import React, { useState, useEffect } from "react";



import "rc-tooltip/assets/bootstrap.css";
import { Helmet } from "react-helmet";
import "react-sortable-tree/style.css";
import "react-toastify/dist/ReactToastify.css";
import "tailwindcss/tailwind.css";



import "@ekonsilio/ekonsilio-ui/src/styles/index.scss";



import "../index.css";


function MyApp({ Component, pageProps }) {
    const [faviconHref, setFaviconHref] = useState("");
    const [logoHref, setLogoHref] = useState("");

    useEffect(() => {
        const getFaviconPath = (isDarkMode = false) => `/favicon-${isDarkMode ? "dark" : "light"}.png`;
        const getLogoPath = (isDarkMode = false) => `/logo192-${isDarkMode ? "dark" : "light"}.png`;

        const matcher = window.matchMedia("(prefers-color-scheme: dark)");

        setFaviconHref(getFaviconPath(matcher.matches));
        setLogoHref(getLogoPath(matcher.matches));

        matcher.onchange = () => {
            setFaviconHref(getFaviconPath(matcher.matches));
            setLogoHref(getLogoPath(matcher.matches))
        };
    }, [faviconHref, logoHref]);

    return (
        <>
            <Helmet>
                <link
                    rel="icon"
                    id="favicon"
                    href={faviconHref}
                    sizes="32x32"
                />
                <link rel="apple-touch-icon" href={logoHref}/>
            </Helmet>
            <Component {...pageProps} />
        </>
    );
}

export default MyApp;
